export const salads = [
  {
    name: 'Зеленый салат',
    price: 520,
    id: 0,
  },
  {
    name: 'Персики & Салат / овечий сыр',
    price: 580,
    id: 1,
  },
  {
    name: 'Севиче из лосося / гель из лемограсса / креветочные чипсы',
    price: 820,
    id: 2,
  },
  {
    name: 'Beef Tar tar / ферментированый желток / картофельное pavé',
    price: 780,
    id: 3,
  },
  {
    name: 'Татаки Тунец & ананас / зеленое масло',
    price: 780,
    id: 4,
  },
  {
    name: 'Брускетта & креветки / авокадо / соус мисо майо',
    price: 850,
    id: 5,
  },
  {
    name: 'Camambert из печи & груша / соленая карамель',
    price: 870,
    id: 6,
  },
  {
    name: 'Паштет из куриной печени & рийет из птицы / персик',
    price: 880,
    id: 7,
  },
  {
    name: 'Фуа-гра / багет / конфи из айвы',
    price: 870,
    id: 8,
  },
  {
    name: 'Брискет / бабагануш & сырный крем',
    price: 780,
    id: 9,
  },
  {
    name: 'Тигровые креветки /хрустящий салат',
    price: 950,
    id: 10,
  },
  {
    name: 'Греческте оливки “L” 100 гр./цитрусовая заправка',
    price: 400,
    id: 11,
  },
  {
    name: 'Печеные перцы / мусс vasabi & сыр Dorblu',
    price: 620,
    id: 12,
  },
]
