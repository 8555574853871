export const grillHot = [
  {
    name: 'Гратен из корнеплодов',
    price: 480,
    id: 1,
  },
  {
    name: 'Нежный Батат / соус béаrnaise / бонито / трюфель',
    price: 620,
    id: 2,
  },
  {
    name: 'Печеный картофель & сыр / трюфельное масло',
    price: 500,
    id: 3,
  },
  {
    name: 'Овощи гриль',
    price: 680,
    id: 0,
  },
  {
    name: 'Кальмар на гриле & брокколи',
    price: 750,
    id: 4,
  },
  {
    name: 'Утиное филе / пюре из кукурузы & соус бордолез',
    price: 1150,
    id: 5,
  },
  {
    name: 'Risotto & мясо краба / цитрусовый кольраби',
    price: 1350,
    id: 6,
  },
  {
    name: 'Бургер с мраморной говядиной & Fries / пармезан',
    price: 860,
    id: 7,
  },
  {
    name: 'Курица терияки / жасмин / Романо',
    price: 750,
    id: 8,
  },
  {
    name: 'Баклажан / свежие овощи / брынза',
    price: 650,
    id: 9,
  },
  {
    name: 'Сибас на гриле 400гр',
    price: 1080,
    id: 10,
  },
  {
    name: 'Креветочная рыба Конгрио & салат фенхель',
    price: 1300,
    id: 11,
  },
  {
    name: 'Северные Гребешки / брокколи на гриле',
    price: 1380,
    id: 12,
  },
  {
    name: 'Марокканский Осьминог & Батат / соус Beurre blanc',
    price: 1600,
    id: 13,
  },
]
