export const desserts = [
  {
    name: 'Шоколадный фондан с соусом англез',
    price: 470,
    id: 2,
  },
  {
    name: 'Cуфле из молочного шоколада / ягоды',
    price: 470,
    id: 3,
  },
  {
    name: 'Лимонный Kérd / манго / фисташки',
    price: 470,
    id: 4,
  },
  {
    name: 'Бородинское // Ванильное // 90 гр.',
    price: 350,
    id: 5,
  },
  {
    name: 'Ягодный // 90 гр.',
    price: 350,
    id: 6,
  },
]
