export const grillSteaks = [
  {
    name: 'Фермерский бык & три соуса / Орел / 100 гр.',
    price: 360,
    id: 1,
  },
  {
    name: 'Tri Tip & гратен из корнеплодов / печеный перец',
    price: 1280,
    id: 2,
  },
  {
    name: 'New York Steak / Брянск / 100гр.',
    price: 680,
    id: 3,
  },
  {
    name: 'Pressé сердца индейки & New York / Соус из йогурта и мяты',
    price: 980,
    id: 4,
  },
]
